
require.register("core-js/modules/_cof.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "core-js");
  (function() {
    var toString = {}.toString;

module.exports = function(it){
  return toString.call(it).slice(8, -1);
};
  })();
});