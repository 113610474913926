
require.register("core-js/modules/_fails.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "core-js");
  (function() {
    module.exports = function(exec){
  try {
    return !!exec();
  } catch(e){
    return true;
  }
};
  })();
});